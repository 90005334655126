import React from 'react';
import { Colors } from '../constants/colors';

const BackArrowIcon = ({ size = 22, color = Colors.WHITE }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5313 3.9375L7.46875 11L14.5313 18.0625"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { BackArrowIcon };
