import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { ArrowTopIcon } from '../images/ArrowTopIcon';
import { Colors } from '../constants/colors';
import { Stack } from '@mui/system';

type Props = {
  onSendMessage: (message: string) => void;
};

const MessageInput = ({ onSendMessage }: Props) => {
  const [message, setMessage] = useState('');
  const [helperText, setHelperText] = useState('');

  const handleSubmit = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage && trimmedMessage.length < 1000) {
      onSendMessage(trimmedMessage);
      setTimeout(() => setMessage(''), 0);
    }
  };

  return (
    <Stack
      sx={{
        marginTop: '20px',
      }}
    >
      {helperText && (
        <Typography
          variant="caption"
          sx={{
            color: Colors.WHITE,
            textAlign: 'center',
            paddingBottom: '2px',
            paddingTop: '2px',
            bgcolor: Colors.RED,
          }}
        >
          {helperText}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          maxHeight: '150px',
          border: `1px solid ${Colors.BLACK}`,
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            if (!helperText && e.target.value.length > 1000) {
              setHelperText('Message is too long');
            } else if (helperText && e.target.value.length < 1000) {
              setHelperText('');
            }
          }}
          placeholder="Write a message..."
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
          multiline
          sx={{
            overflowY: 'auto',
            border: 'none',
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          sx={{ width: '48px', borderRadius: 0 }}
          disabled={!!helperText}
        >
          <ArrowTopIcon />
        </Button>
      </Box>
    </Stack>
  );
};

export { MessageInput };
