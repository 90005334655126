import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Colors } from '../constants/colors';
import { isAgentMessage } from '../utils/message';
import { format } from 'date-fns';
import { Message } from '../types/message';
import { AttachmentIcon } from '../images/AttachmentIcon';

const urlCheck = /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/;

type Props = {
  message: Message;
};

const MessageBubble = ({ message }: Props) => {
  const isAgent = isAgentMessage(message);

  const text = useMemo(() => {
    let messageText = message.content;
    if (urlCheck.test(messageText)) {
      const result = urlCheck.exec(messageText);
      const url = result ? result[0] : null;
      if (url) {
        const urlWithHttps = url.startsWith('http') ? url : `https://${url}`;
        messageText = messageText.replace(url, `<a href="${urlWithHttps}" target="_blank" rel="noreferrer">${url}</a>`);
      }
    }
    return messageText;
  }, [message.content]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: isAgent ? '100%' : 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 1,
            bgcolor: isAgent ? undefined : Colors.YELLOW,
            border: isAgent ? 1 : 0,
            borderColor: isAgent ? Colors.GREY : 'transparent',
            borderRadius: 4,
            p: 2,
            wordBreak: 'break-word',
          }}
        >
          {isAgent && (
            <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'bold', mb: 1 }}>
              {message.authorName} {message.vendorName ? `(${message.vendorName})` : ''}
            </Typography>
          )}
          {message.contentType === 'text' && (
            <Typography sx={{ color: 'text.primary' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </Typography>
          )}
          {message.contentType === 'image' && (
            <img
              src={JSON.parse(message.content).url}
              alt={JSON.parse(message.content).fileName}
              style={{ width: '100%' }}
            />
          )}
          {message.contentType === 'file' && (
            <a href={JSON.parse(message.content).url} target="_blank" rel="noreferrer">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  background: Colors.GREY,
                  borderRadius: 4,
                  p: 2,
                }}
              >
                <AttachmentIcon color={Colors.BLACK} size={20} />
                <Typography sx={{ color: Colors.BLACK, marginLeft: 1 }}>
                  {JSON.parse(message.content).fileName}
                </Typography>
              </Box>
            </a>
          )}
        </Box>
        {isAgent && message.authorAvatarUrl && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              borderRadius: '50%',
              ml: 1,
              border: 1,
            }}
          >
            <img src={message.authorAvatarUrl} alt="avatar" style={{ width: 40, height: 40, borderRadius: '50%' }} />
          </Box>
        )}
      </Box>
      <Typography sx={{ color: 'text.secondary', fontSize: 12, textAlign: isAgent ? 'left' : 'right' }}>
        {format(new Date(message.timestamp ?? new Date()), 'HH:mm a')}
      </Typography>
    </Box>
  );
};

export { MessageBubble };
