import { createTheme } from '@mui/material';
import { Colors } from '../constants/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.YELLOW,
    },
    secondary: {
      main: Colors.BLACK,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          borderColor: Colors.GREY,
          borderWidth: '1px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: Colors.BLACK,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }

      `,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});
