import React, { useEffect } from 'react';
import { CircularProgress, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ChatType } from '../types/conversation';
import { Colors } from '../constants/colors';
import { Box } from '@mui/system';

type Props = {
  onSelect: (sessionId: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  list: ChatType[];
  setList: (list: ChatType[]) => void;
};

const ConversationList = ({ onSelect, loading, setLoading, list, setList }: Props) => {
  useEffect(() => {
    const sessions = localStorage.getItem('sessions');
    if (sessions) {
      const parsedSessions: ChatType[] = JSON.parse(sessions);
      setList(parsedSessions);
    }
    // eslint-disable-next-line
  }, []);

  const onItemSelect = (sessionId: string) => {
    setLoading(true);
    onSelect(sessionId);
  };

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 16, paddingBottom: 4, textAlign: 'center' }}>
        {list?.length > 0 ? 'All Dealer Chats' : 'No recent chats'}
      </Typography>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', marginTop: 40 }}>
          <CircularProgress color="primary" />
        </Box>
      )}
      <List
        sx={{
          overflowY: 'auto',
          paddingBottom: 8,
        }}
      >
        {list.map((item) => (
          <ListItemButton
            key={item.sessionId}
            onClick={() => onItemSelect(item.sessionId)}
            sx={{
              border: `1px solid ${Colors.GREY}`,
              margin: 2,
              borderRadius: 4,
            }}
            disabled={loading}
          >
            <ListItemText
              primary={item.vendorId}
              secondary={item.topic}
              primaryTypographyProps={{
                component: 'h5',
              }}
              secondaryTypographyProps={{
                component: 'span',
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export { ConversationList };
