import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:81';

export const getSocket = (sessionId: string) => {
  return io(URL, {
    extraHeaders: {
      sessionid: sessionId,
    },
  });
};
