import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getSocket } from '../socket';
import { MemberFromServerMessage, Message, MessageFromServer, TypingMessage } from '../types/message';
import { Box, Container } from '@mui/system';
import { Badge, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { MessageList } from './MessageList';
import { MessageInput } from './MessageInput';
import { LoginForm } from './LoginForm';
import axios, { AxiosError } from 'axios';
import { Colors } from '../constants/colors';
import { useIsTabActive } from '../hooks/useIsTabActive';
import { useSearchParams } from 'react-router-dom';
import { CloseIcon } from '../images/CloseIcon';
import { Socket } from 'socket.io-client';
import { ChatType, UserType } from '../types/conversation';
import { ConversationList } from './ConversationList';
import { MessageIcon } from '../images/MessageIcon';
import { BackArrowIcon } from '../images/BackArrowIcon';
import { ConversationTopic } from './ConversationTopic';

const PAGE_TITLE = document.title;

type Props = {
  initialSessionId?: string | null;
  initialVendorId?: string;
};

const Conversation = ({ initialSessionId = null, initialVendorId }: Props) => {
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [membersJoined, setMembersJoined] = useState<MemberFromServerMessage[]>([]);
  const [params] = useSearchParams();
  const [sessionId, setSessionId] = useState<string | null>(initialSessionId);
  const isFocused = useIsTabActive();
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const [vendorId, setVendorId] = useState<string | null>(params.get('vendorid'));
  const [socket, setSocket] = useState<Socket | null>(null);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ChatType[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('test');

  const clearMessages = () => {
    setMessages([]);
    setMembersJoined([]);
  };

  const onConnect = () => {
    clearMessages();
    setIsConnected(true);
    setLoading(false);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const onMessageFromServer = useCallback(
    (value: MessageFromServer) => {
      setMessages((previous) => [
        ...previous,
        {
          content: value.content,
          contentType: value.contentType,
          authorName: value.authorName,
          timestamp: value.timestamp,
          requestId: value.requestId,
          authorAvatarUrl: value.authorAvatarUrl,
          vendorName: value.vendorName,
        },
      ]);
      setIsTyping(false);
      const isNewMessage = new Date(value.timestamp).getTime() > new Date().getTime() - 5000;
      if (!value.requestId && isNewMessage) {
        const notification = new Audio('notification.wav');
        notification.play();
      }
      if (!isFocused || !show) {
        intervalRef.current && clearInterval(intervalRef.current);
        document.title = `(New message!) ${PAGE_TITLE}`;
        intervalRef.current = setInterval(() => {
          if (document.title === PAGE_TITLE) {
            document.title = `(New message!) ${PAGE_TITLE}`;
          } else {
            document.title = PAGE_TITLE;
          }
        }, 2500);
      }
    },
    [isFocused, show],
  );

  useEffect(() => {
    if (isFocused && show) {
      intervalRef.current && clearInterval(intervalRef.current);
      intervalRef.current = null;
      document.title = PAGE_TITLE;
    }
  }, [isFocused, show]);

  const fetchConversationDetails = useCallback(
    async (sessionId: string, isNewConversation: boolean) => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/session/${sessionId}`);
        setVendorId(data.vendorId);
        setTopic(data.topic);
        if (isNewConversation) {
          const vendorName = data.vendorName ?? vendorId ?? initialVendorId;
          updateSessionsInLocalStorage(data.sessionId, data.topic, vendorName);

          setList((prev) => [...prev, { sessionId: data.sessionId, topic: data.topic, vendorId: vendorName }]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [initialVendorId, vendorId],
  );

  const connectToSocket = useCallback(async () => {
    if (!sessionId) {
      return;
    }
    try {
      clearMessages();
      if (socket) {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
      }
      const soc = getSocket(sessionId);
      soc.on('connect', onConnect);
      soc.on('disconnect', onDisconnect);
      soc.connect();
      fetchConversationDetails(sessionId, false);
      setSocket(soc);
    } catch (error) {
      console.error(error);
      setSessionId(null);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [sessionId]);

  useEffect(() => {
    updateSessionIdIfProvidedInUrl();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket]);

  // eslint-disable-next-line
  const onError = useCallback((error: any) => {}, []);

  const onTypingFromServer = useCallback((value: TypingMessage) => {
    timerRef.current && clearTimeout(timerRef.current);
    setIsTyping(value.isTyping);

    if (value.isTyping) {
      timerRef.current = setTimeout(() => {
        setIsTyping(false);
      }, value.timeoutMs);
    }
  }, []);

  const onMemberFromServer = useCallback((value: MemberFromServerMessage) => {
    setMembersJoined((prev) => [...prev, value]);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('messageFromServer', onMessageFromServer);
      socket.on('error', onError);
      socket.on('typingFromServer', onTypingFromServer);
      socket.on('memberFromServer', onMemberFromServer);
    }
    return () => {
      socket?.off('messageFromServer', onMessageFromServer);
      socket?.off('error', onError);
      socket?.off('typingFromServer', onTypingFromServer);
      socket?.off('memberFromServer', onMemberFromServer);
    };
  }, [socket, onMessageFromServer, onError, onTypingFromServer, onMemberFromServer]);

  useEffect(() => {
    return () => {
      socket?.off('connect', onConnect);
      socket?.off('disconnect', onDisconnect);
    };
    // eslint-disable-next-line
  }, []);

  const onSendMessage = (text: string) => {
    socket?.emit('messageToServer', {
      text,
      requestId: '123',
    });
  };

  const updateSessionsInLocalStorage = (sessionId: string, topic?: string, vendorId?: string) => {
    const sessions = localStorage.getItem('sessions');
    if (sessions) {
      const parsedSessions: ChatType[] = JSON.parse(sessions);
      const session = parsedSessions.find((s: { sessionId: string }) => s.sessionId === sessionId);
      if (!session) {
        localStorage.setItem(
          'sessions',
          JSON.stringify([...parsedSessions, { sessionId, topic, vendorId } as ChatType]),
        );
      }
    } else {
      localStorage.setItem('sessions', JSON.stringify([{ sessionId, topic, vendorId } as ChatType]));
    }
  };

  useEffect(() => {
    const sessions = localStorage.getItem('sessions');
    if (sessions) {
      const parsedSessions: ChatType[] = JSON.parse(sessions);
      if (parsedSessions.length === 0) {
        setShowLogin(true);
        return;
      }
    }
    setShowLogin(false);
  }, []);

  const updateSessionIdIfProvidedInUrl = () => {
    const id = params.get('sessionid');
    setSessionId(id);
    if (id) {
      setShow(true);
    }
  };

  useEffect(() => {
    const sessions = localStorage.getItem('sessions');
    if (sessions) {
      const parsedSessions: ChatType[] = JSON.parse(sessions);
      setList(parsedSessions);
    }
    // eslint-disable-next-line
  }, []);

  const onLogin = async ({
    name,
    email,
    topic,
    vendorId: vid,
  }: {
    name?: string;
    email?: string;
    topic: string;
    vendorId?: string;
  }) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/session`;
      const { data } = await axios.post(url, {
        userName: name,
        userEmail: email,
        topic,
        vendorId: vid ?? vendorId,
      });
      setSessionId(data.sessionId);
      fetchConversationDetails(data.sessionId, true);
    } catch (error) {
      console.error(error);
      const e = error as AxiosError<{ message: string[] | string }>;
      setLoading(false);
      return e.response?.data.message;
    }
  };

  const initChat = ({
    vendorId: vid,
    sessionId = null,
    name: initialName,
    email: initialEmail,
    topic: initialTopic,
  }: {
    vendorId?: string;
    sessionId?: string | null;
    name?: string;
    email?: string;
    topic?: string;
  }) => {
    socket?.disconnect();
    setIsConnected(false);
    setShow(true);
    setSessionId(sessionId);
    clearMessages();
    setVendorId(vid ?? null);
    const user: UserType = JSON.parse(localStorage.getItem('user') ?? '{}');
    setName(initialName ?? user.userName ?? '');
    setEmail(initialEmail ?? user.userEmail ?? '');
    setTopic(initialTopic ?? '');
    if (!sessionId && (!initialEmail || !initialName || !initialTopic)) {
      setShowLogin(true);
    } else if (initialEmail && initialName && initialTopic) {
      onLogin({ name: initialName, email: initialEmail, topic: initialTopic, vendorId: vid });
    }
  };

  const onSelectedSession = (sessionId: string) => {
    setSessionId(sessionId);
    if (sessionId) {
      updateSessionsInLocalStorage(sessionId);
    }
  };

  const onBackPress = () => {
    if (isConnected) {
      socket?.disconnect();
      setIsConnected(false);
      setShowLogin(false);
      clearMessages();
      setSessionId(null);
    }
    if (!isConnected && showLogin) {
      setShowLogin(false);
    }
  };

  const onOpenChat = (e: Event) => {
    e.stopImmediatePropagation();
    initChat({
      vendorId: (e as CustomEvent).detail?.vendorId,
      sessionId: (e as CustomEvent).detail?.sessionId,
      name: (e as CustomEvent).detail?.name,
      email: (e as CustomEvent).detail?.email,
      topic: (e as CustomEvent).detail?.topic,
    });
  };

  useEffect(() => {
    window.addEventListener('openChat', onOpenChat);

    return () => {
      window.removeEventListener('openChat', onOpenChat);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {show && (
        <Container maxWidth="sm">
          <Paper
            elevation={3}
            style={{
              padding: 0,
              height: window.innerHeight > 700 ? '650px' : '90%',
              width: '360px',
              display: 'flex',
              flexDirection: 'column',
              position: 'fixed',
              right: 16,
              bottom: 16,
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                bgcolor: Colors.BLACK,
                color: Colors.WHITE,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 2,
                paddingBottom: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {(isConnected || (showLogin && list.length > 0)) && (
                <Tooltip title="Back to all messages">
                  <IconButton onClick={onBackPress} sx={{ alignItems: 'center' }}>
                    <BackArrowIcon size={24} color={Colors.WHITE} />
                    <Typography
                      variant="h6"
                      sx={{ color: Colors.WHITE, fontSize: '16px', m: 0, marginBottom: '2px' }}
                      align="left"
                    >
                      Back
                    </Typography>
                  </IconButton>
                </Tooltip>
              )}
              <Typography variant="h6" sx={{ color: Colors.WHITE, fontSize: '16px' }} align="left">
                RideSHOP Live Chat
              </Typography>
              <IconButton onClick={() => setShow(false)}>
                <CloseIcon size={24} color={Colors.WHITE} />
              </IconButton>
            </Box>
            {isConnected && (
              <>
                <ConversationTopic topic={topic} />
                <MessageList messages={messages} isTyping={isTyping} membersJoined={membersJoined} />
                <MessageInput onSendMessage={onSendMessage} />
              </>
            )}
            {!isConnected && (
              <>
                {!showLogin && (
                  <ConversationList
                    onSelect={onSelectedSession}
                    loading={loading}
                    setLoading={setLoading}
                    list={list}
                    setList={setList}
                  />
                )}
                {showLogin && (
                  <LoginForm
                    onSave={onLogin}
                    showLoading={loading}
                    initialName={name}
                    initialEmail={email}
                    initialTopic={topic}
                  />
                )}
              </>
            )}
          </Paper>
        </Container>
      )}
      {!show && list.length > 0 && (
        <IconButton
          aria-label="show chat"
          size="large"
          onClick={() => setShow(true)}
          sx={{
            position: 'fixed',
            right: 16,
            bottom: 16,
            backgroundColor: Colors.YELLOW,
            zIndex: 1000,
          }}
        >
          <Badge badgeContent={intervalRef.current ? '!' : 0} color="secondary">
            <MessageIcon />
          </Badge>
        </IconButton>
      )}
    </>
  );
};

export { Conversation };
