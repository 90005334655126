import React from 'react';
import { Box, css, keyframes } from '@mui/system';
import { Colors } from '../constants/colors';
import { Typography } from '@mui/material';

const loadingAnimation = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`;

const loading = css`
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 2ch 0 0);
  animation: ${loadingAnimation} 1s steps(3) infinite;
`;

const MessageBubbleTyping = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 1,
            backgroundColor: Colors.GREY,
            borderRadius: 4,
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography sx={{ ...loading, color: 'text.primary' }} className="loading">
            • • •
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { MessageBubbleTyping };
