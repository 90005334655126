import React from 'react';
import { Colors } from '../constants/colors';

const CloseIcon = ({ size = 42, color = Colors.BLACK }) => (
  <svg width={size} height={size} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.25 8.75L8.75 33.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75 8.75L33.25 33.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export { CloseIcon };
