import React from 'react';
import { Typography } from '@mui/material';
import { Colors } from '../constants/colors';

type Props = {
  topic: string;
};

const ConversationTopic = ({ topic }: Props) => {
  return (
    <Typography variant="body2" sx={{ px: 4, py: 2, borderBottom: `1px solid ${Colors.GREY}`, color: Colors.GREY20 }}>
      Topic: {topic}
    </Typography>
  );
};

export { ConversationTopic };
