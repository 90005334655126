import React, { useEffect, useMemo, useRef } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { MemberFromServerMessage, Message, MessagePerDate } from '../types/message';
import { MessageBubble } from './MessageBubble';
import { isAgentMessage, isMessage } from '../utils/message';
import { MessageBubbleTyping } from './MessageBubbleTyping';

type Props = {
  messages: Message[];
  isTyping: boolean;
  membersJoined: MemberFromServerMessage[];
};

const MessageList = ({ messages, isTyping, membersJoined }: Props) => {
  const listEndRef = useRef<HTMLUListElement | null>(null);

  const scrollToBottom = () => {
    if (listEndRef.current) {
      listEndRef.current?.scrollTo(0, listEndRef.current?.scrollHeight);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const messagesPerDay = useMemo(() => {
    const messagesPerDay: MessagePerDate[] = [];
    let membersJoinedIndex = 0;

    const membersJoinedSorted = membersJoined.toSorted((a, b) => a.timestamp - b.timestamp);

    messages
      .toSorted((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .forEach((message) => {
        if (!message.timestamp) {
          return;
        }
        const date = new Date(message.timestamp).toLocaleDateString();
        let lastMessagePerDay = messagesPerDay.findIndex((item) => item.date === date);
        if (lastMessagePerDay === -1) {
          messagesPerDay.push({ date, messages: [] });
          lastMessagePerDay = messagesPerDay.length - 1;
        }

        while (membersJoinedSorted[membersJoinedIndex]?.timestamp < new Date(message.timestamp).getTime()) {
          messagesPerDay[lastMessagePerDay].messages.push(membersJoinedSorted[membersJoinedIndex]);
          membersJoinedIndex++;
        }

        messagesPerDay[lastMessagePerDay].messages.push(message);
      });

    if (messagesPerDay.length === 0 && membersJoinedSorted.length > 0) {
      messagesPerDay.push({ date: new Date(membersJoinedSorted[0].timestamp).toLocaleDateString(), messages: [] });
      membersJoinedSorted.forEach((member) => {
        messagesPerDay[messagesPerDay.length - 1].messages.push(member);
        membersJoinedIndex++;
      });
    }
    if (membersJoinedSorted.length > membersJoinedIndex) {
      membersJoinedSorted.forEach((member) => {
        messagesPerDay[0].messages.push(member);
      });
    }
    setTimeout(() => {
      scrollToBottom();
    }, 100);

    return messagesPerDay;
  }, [messages, membersJoined]);

  return (
    <List style={{ flex: 1, flexGrow: 1, overflowY: 'auto' }} ref={listEndRef}>
      {messagesPerDay.length === 0 && (
        <ListItem
          sx={{
            flex: 1,
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Welcome to Helmet House!
          </Typography>
        </ListItem>
      )}
      {messagesPerDay.map((messagesFromDay, index) => (
        <>
          <ListItem key={`${messagesFromDay.date}`}>
            <Typography
              variant="caption"
              sx={{ flex: 1, flexGrow: 1, justifyContent: 'center', mb: 1 }}
              textAlign="center"
            >
              {messagesFromDay.date}
            </Typography>
          </ListItem>
          {messagesFromDay.messages.map((message, index2) => (
            <>
              {isMessage(message) ? (
                <ListItem
                  key={`${index}-${index2}`}
                  sx={{
                    flex: 1,
                    flexGrow: 1,
                    justifyContent: isAgentMessage(message) ? 'flex-start' : 'flex-end',
                  }}
                >
                  <MessageBubble message={message} />
                </ListItem>
              ) : (
                <ListItem
                  key={`${index}-${index2}`}
                  sx={{
                    flex: 1,
                    flexGrow: 1,
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {message.agentName} {message.status === 'join' ? 'joined' : 'left'} the chat
                  </Typography>
                </ListItem>
              )}
            </>
          ))}
          {isTyping && (
            <ListItem
              key="typing"
              sx={{
                flex: 1,
                flexGrow: 1,
                justifyContent: 'flex-start',
              }}
            >
              <MessageBubbleTyping />
            </ListItem>
          )}
        </>
      ))}
    </List>
  );
};

export { MessageList };
