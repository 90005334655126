import React, { useCallback, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

type Props = {
  onSave: ({
    name,
    email,
    topic,
  }: {
    name?: string;
    email?: string;
    topic: string;
  }) => Promise<string[] | string | undefined>;
  showLoading: boolean;
  initialName?: string;
  initialTopic?: string;
  initialEmail?: string;
};

const LoginForm = ({ onSave, showLoading, initialName = '', initialEmail = '', initialTopic = '' }: Props) => {
  const [errors, setErrors] = useState<string[]>();
  const [name, setName] = useState(initialName);
  const [email, setEmail] = useState(initialEmail);
  const [topic, setTopic] = useState(initialTopic);

  const handleSubmit = useCallback(async () => {
    if (name.trim() && email.trim() && topic.trim()) {
      const result = await onSave({
        name,
        email,
        topic,
      });
      if (result) {
        setErrors(Array.isArray(result) ? result : [result]);
      }
    } else {
      setErrors(['Please fill in all fields']);
    }
  }, [name, email, topic, onSave]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ textAlign: 'center' }}>
          <img src="https://app.helmethousechat.com/images/helmet.png" alt="helmet" height={150} />
        </Box>
        <Typography variant="body1" component="h2" style={{ marginTop: 8, marginBottom: 20, textAlign: 'center' }}>
          Please complete the form before starting the chat
        </Typography>
        <TextField
          type="text"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
          onKeyDown={onKeyDown}
        />
        <TextField
          type="email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your Email"
          style={{ marginTop: 4, marginBottom: 4 }}
          onKeyDown={onKeyDown}
        />
        <TextField
          type="text"
          variant="outlined"
          fullWidth
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="How can we help you?"
          sx={{ marginTop: 1 }}
          onKeyDown={onKeyDown}
        />
        {errors &&
          errors.map((error) => (
            <Typography key={error} component="p" color="red">
              {error}
            </Typography>
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%', marginTop: 20 }}
          onClick={handleSubmit}
          disableElevation
          disabled={showLoading}
        >
          {showLoading ? 'Connecting...' : 'Start'}
        </Button>
      </Box>
    </Box>
  );
};

export { LoginForm };
