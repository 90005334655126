import React, { useCallback, useMemo, useState } from 'react';
import { Conversation } from './Conversation';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from '../utils/theme';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

type Props = {
  sessionId?: string;
  vendorId?: string;
};

const ConversationComponent = ({ sessionId, vendorId }: Props) => {
  const [domRef, setDomRef] = useState<HTMLStyleElement>();

  const cache = useMemo(() => {
    if (domRef) {
      return createCache({
        key: 'css',
        prepend: true,
        container: domRef,
      });
    } else {
      return null;
    }
  }, [domRef]);

  const getRef = useCallback((ref: HTMLStyleElement | null) => {
    if (ref) {
      setDomRef(ref);
    }
  }, []);

  return (
    <>
      <style ref={getRef}></style>
      {cache && (
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Conversation initialSessionId={sessionId} initialVendorId={vendorId} />
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      )}
    </>
  );
};

export { ConversationComponent };
