import React from 'react';
import ReactDOM from 'react-dom/client';
import r2wc from 'react-to-webcomponent';
import { ConversationComponent } from './components/ConversationComponent';

const component = r2wc(ConversationComponent, React, ReactDOM, {
  shadow: 'closed',
  props: {
    sessionId: 'string',
    vendorId: 'string',
  },
});

customElements.define('chat-component', component);
const chatElement = document.createElement('chat-component');

document.body.appendChild(chatElement);
